//示例，根据项目修改或重新创建
import https from "../https";
import config from "@/config";

export default {
  getvolunteer(params) {
    return https.get(`${config.baseUrl}/api/info/list`, params);
  },
  getintroduce(params) {
    return https.get(`${config.baseUrl}/api/info/getByNode`, params);
  },
};