//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/learnplatfrom",
        name: "Learnplatfrom",
        component: _import("Learnplatform/index"),
        meta: {
          bread: ["学习平台", "红十字运动知识"]
        }
      },
      {
        path: "/bookstore",
        name: "Bookstore",
        component: _import("Learnplatform/bookstore/bookstore"),
        meta: {
          bread: ["学习平台", "网上书屋"]
        }
      },
      {
        path: "/lifeheath",
        name: "Lifeheath",
        component: _import("Learnplatform/Lifeheath/index"),
        meta: {
          bread: ["学习平台", "生命健康"]
        }
      },
      {
        path: "/business",
        name: "Business",
        component: _import("Learnplatform/business/index"),
        meta: {
          bread: ["学习平台", "规范性文件"]
        }
      },
      {
        path: "/lawknowledge",
        name: "Lawknowledge",
        component: _import("Learnplatform/lawknowledge/index"),
        meta: {
          bread: ["学习平台", "政策法规"]
        }
      }
    ],
  },
];
export default routes;