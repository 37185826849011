//示例，根据项目修改或重新创建
import https from "../https";
import config from "@/config";

export default {
    // 救助行动列表
    // lovetoall
    // helppublic
  gethelpdate(params) {
    return https.get(`${config.baseUrl}/api/info/list`, params);
  },
  // 小天使基金数据
  getangelfund(params) {
    return https.get(`${config.baseUrl}/api/info/getByNode`, params);
  }
};