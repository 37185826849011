<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
	import apiUrls from "@api";
	export default{
		  watch: {  
		    '$route': {
		      handler(to, from) {
				  // console.log(to)
				  let hrefs =  encodeURIComponent(document.location.href);
				  apiUrls.visitLog({url:hrefs})
		      },
		      immediate: true  // 首次进入页面即执行
		    }  
		  }
		
		
	}
	
	
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}
html,
body {
  /deep/ .popper__arrow {
    display: none !important;
  }
  /deep/ .el-select-dropdown__item .hover {
color: #606266 !important;
  }
  /deep/.el-select-dropdown__item .hover,
  .el-select-dropdown__item:hover {
    background-color: rgb(219, 64, 65);
    color: #ffffff !important;
    a{
      color: #ffffff !important;
    }
  }
  // /deep/ .selected a {
  //   color: #ffffff !important;
  // }
  // /deep/.hover a {
  //   color: #ffffff !important;
  // }
  
}
</style>
