//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/imgDetail",
    children: [
      {
        path: "/imgDetail",
        name: "imgDetail",
        component: _import("detail/imgDetail"),
        meta:{
          bread: ['更多']
        }
      },
      {
        path: "/textDetail",
        name: "textDetail",
        component: _import("detail/textDetail"),
        meta:{
          isFirst:1
        }
      },
      {
        path: "/searchResult",
        name: "searchResult",
        component: _import("detail/searchResult"),
        meta:{
          isFirst:1
        }
      },
      {
        path: "/videoDetail",
        name: "videoDetail",
        component: _import("detail/videoDetail"),
        meta:{
          isFirst:1
        }
      },
      {
        path: "/detailShow",
        name: "detailShow",
        component: _import("detail/detail"),
        meta:{
          isFirst:2
        }
      },
      {
        path: "/videoShow",
        name: "videoShow",
        component: _import("detail/videoShow"),
        meta:{
          isFirst:2
        }
      },
	  {
	    path: "/detail2",
	    name: "Detail2",
	    component: _import("detail/detail2"),
	    meta:{
	      isFirst:2
	    }
	  },
    ],
  },
];
export default routes;
