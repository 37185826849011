//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: _import("home/home"),
        meta:{
          breadNum:1
        }
      },
      {
        path: "/donateInfo",
        name: "donateInfo",
        component: _import("home/donateInfo"),
        meta: {
          bread: ["信息公开", "捐赠信息公示"],
        },
      },
      {
        path: "/email",
        name: "email",
        component: _import("home/email"),
        meta: {
          bread: ["首页", "会长信箱"],
        },
      },
      {
        path: "/idonate",
        name: "idonate",
        component: _import("home/idonate"),
        meta: {
          bread: ["首页", "我要捐赠"],
        },
      },
	  {
	    path: "/agree",
	    name: "agree",
	    component: _import("home/agree"),
	    meta: {
	      bread: ["首页", "用户协议"],
	    },
	  },
	  {
	    path: "/idonateAPP",
	    name: "idonateAPP",
	    component: _import("home/idonateAPP"),
	    meta: {
	      bread: ["首页", "我要捐赠"],
	    },
	  },
      {
        path: "/certificate",
        name: "certificate",
        component: _import("home/certificate"),
        meta: {
          bread: ["首页", "我要捐赠","证书"],
        },
      },
      {
        path: "/complain",
        name: "complain",
        component: _import("home/complain"),
        meta: {
          bread: ["首页", "我要参与"],
        },
      },
      {
        path: "/help",
        name: "help",
        component: _import("home/help"),
        meta: {
          bread: ["首页", "我要求助"],
        },
      },{
        path: "/partyBuilding",
        name: "PartyBuilding",
        component: _import("home/partyBuilding"),
        meta: {
          bread: [ "学党史 悟思想 跟党走"],
        },
      },{
        path: "/esd",
        name: "esd",
        component: _import("home/esd"),
        meta: {
          bread: [ "认真学习贯彻党的二十大精神"],
        },
      },{
        path: "/ztjy",
        name: "ztjy",
        component: _import("home/ztjy"),
        meta: {
          bread: [ "学习贯彻习近平新时代中国特色社会主义思想主题教育"],
        },
      }
      
    ],
  },
];
export default routes;
