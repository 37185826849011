import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import echarts from "echarts";
import sichuan from 'echarts/map/json/province/sichuan.json';
import "./commen.less";
import element from "element-ui";
import "./assets/icon/icon.css";
import "element-ui/lib/theme-chalk/index.css";
import VueUploadImgs from 'vue-upload-imgs'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

echarts.registerMap('sichuan', sichuan)
Vue.use(element);
Vue.use(VueUploadImgs)
Vue.filter("formateData", value => {
  value = value.split("-").join(".");
  return value;
});
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.prototype.$firstBread = true;
Vue.prototype.$goto = (path, query = {}) => {
  router.push({ path, query });
};
Vue.prototype.$spliceStr = str => {
  str.splice(0, 100) + "...";
};

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
