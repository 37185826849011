//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/volunteer2",
        name: "Volunteer2",
        component: _import("Volunteer2/volunteer2"),
        meta: {
          bread: ["志愿服务与基层组织","红十字·志愿者"]
        }
      },
      {
        path: "/ldge",
        name: "Ldge",
        component: _import("Volunteer2/ldge"),
        meta: {
          bread: ["志愿服务与基层组织","南丁格尔志愿服务队"],
        }
      },
      {
        path: "/gmyljg",
        name: "Gmyljg",
        component: _import("Volunteer2/gmyljg"),
        meta: {
          bread: ["志愿服务与基层组织","冠名医疗机构"],
        }
      },
	  {
	    path: "/gmyljg2",
	    name: "Gmyljg2",
	    component: _import("Volunteer2/gmyljg2"),
	    meta: {
	      bread: ["志愿服务与基层组织","基层组织"],
	    }
	  }
    ],
  },
];
export default routes;