//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/orgIntroduce",
    children: [
      {
        path: "/organization",
        name: "organization",
        component: _import("organization/index"),
        meta: {
          bread: ["组织概况", "市州红十字会"],
        },
      },
      {
        path: "/big",
        name: "big",
        component: _import("organization/big"),
        meta: {
          bread: ["组织概况", "大事记"],
        },
      },
      {
        path: "/bossIntro",
        name: "bossIntro",
        component: _import("organization/bossIntro"),
        meta: {
          bread: ["组织概况", "领导机构"],
        },
      },
      {
        path: "/smart",
        name: "smart",
        component: _import("organization/smart"),
        meta: {
          bread: ["组织概况", "红十字智库"],
        },
      },
      {
        path: "/orgIntroduce",
        name: "orgIntroduce",
        component: _import("organization/introduce"),
        meta: {
          bread: ["组织概况", "组织简介"],
        },
      },
      {
        path: "/underOrganization",
        name: "underOrganization",
        component: _import("organization/underOrganization"),
        meta: {
          bread: ["组织概况", "直属单位"],
        },
      },
    ],
  },
];
export default routes;
