//示例，根据项目修改或重新创建
import https from "../https";
import config from "@/config";

export default {
    //获取工作动态列表
   getbodydonate(params) {
    return https.get(`${config.baseUrl}/api/info/list`, params);
  },
   // 获取统计数据
   getStatistics(params) {
    return https.get(`${config.baseUrl}/api/info/getByNode`, params);
   },
   //获取献花点烛数据
   getFlowerCandle(params) {
	   return https.get(`${config.baseUrl}/api/comment/list`, params);
   },
   //保存献花点烛数据
   saveFlowerCandle(params) {
   	   return https.get(`${config.baseUrl}/api/comment/save.do`, params);
   }
};